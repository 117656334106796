<template lang="pug">
  div.homepage
    section(id="hero").hero
      div.container
        div.hero__wrapper
          div.hero__info
            img(:src="HeroMobile" alt="link").hero__mob
            h1 Weather
            p Our mobile weather application is your reliable companion in the world of meteorology. Get accurate weather forecasts in real time, find out about the current temperature, humidity, wind speed and much more. Thanks to the intuitive interface and colorful maps, you will easily find the information you need. It doesn't matter if you are at home or on a trip, our app is always there, ready to help you prepare for any weather conditions.
            a(href="https://apps.apple.com/us/app/weather-forecast-live-radar/id6472042471" target="_blank").hero__link
              img(:src="MainBtn" alt="link")
              div.hero__link-wrap
                p Download on the
                p App Store
          div.hero__img
            img(:src="Telephones" alt="telephones")

    section.app
      div.container
        h2 Top features of our app
        VueSlickCarousel(v-bind="settings2")
          div(v-for="app in apps").app__wrapper
            div.app__pic
              img(:src="app.mainPic" alt="app")
            div.app__info
              h4 {{ app.title }}
              p {{ app.description }}
              div.app__img
                img(:src="app.decPic" alt="app")

    section.features
      div.container
        h2 Unlock premium features with free trial :
        ul
          li(v-for="feature in features")
            img(:src="feature.img" alt="feature")
            h5 {{ feature.title }}
            p Get Free instant to premium features

    div.moon-decor
      div.moon-decor__img
        img(:src="Moon" alt="moon")

    section.current
      div.container
        h2 Current weather conditions day and night on your screen
        VueSlickCarousel(v-bind="settings").current__list
          div(v-for="current in currents").current__item
            img(:src="current.img" alt="weather")
            h5 {{ current.title }}

    section(id="contact").contact
      div.container
        div.contact__wrapper
          div.contact__sun
            img(:src="Sun" alt="sun")
          div.contact__form
            h2 Contact Us
            p Get in touch with us for assistance and inquiries.
            form(@submit="submit")
              label
                input(type="email" placeholder="Email"  v-model="email" required)
              label
                input(type="text" placeholder="Name" v-model="full_name" required)
              label
                textarea(placeholder="Message" v-model="additional_info" required)
              button(type="submit").btn Send

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import F1 from '@/assets/images/f1.svg'
import F2 from '@/assets/images/f2.svg'
import F3 from '@/assets/images/f3.svg'
import F4 from '@/assets/images/f4.svg'
import Moon from '@/assets/images/moondecor.svg'
import Current1 from '@/assets/images/current1.svg'
import Current2 from '@/assets/images/current2.svg'
import Current3 from '@/assets/images/current3.svg'
import Current4 from '@/assets/images/current4.svg'
import Current5 from '@/assets/images/current5.svg'
import Current6 from '@/assets/images/current6.svg'
import Sun from '@/assets/images/form-sun.svg'
import Footer from '@/assets/images/avalon.svg'
import MainBtn from '@/assets/images/main-btn.svg'
import Telephones from '@/assets/images/telephones.png'
import App1 from '@/assets/images/app1.png'
import App2 from '@/assets/images/app2.png'
import App3 from '@/assets/images/app3.png'
import App4 from '@/assets/images/app4.png'
import App5 from '@/assets/images/app5.png'
import App6 from '@/assets/images/app6.png'
import App7 from '@/assets/images/app7.png'
import App8 from '@/assets/images/app8.png'
import AppDec1 from '@/assets/images/app-dec1.svg'
import AppDec2 from '@/assets/images/app-dec2.svg'
import AppDec3 from '@/assets/images/app-dec3.svg'
import AppDec4 from '@/assets/images/app-dec4.svg'
import AppDec5 from '@/assets/images/app-dec5.svg'
import AppDec6 from '@/assets/images/app-dec6.svg'
import AppDec7 from '@/assets/images/app-dec7.svg'
import AppDec8 from '@/assets/images/app-dec8.svg'
import HeroMobile from '@/assets/images/hero-mob.svg'

export default {
  name: 'HomePage',
  components: { VueSlickCarousel },
  data () {
    return {
      settings2: {
        slidesToShow: 1,
        dots: false,
        cssEase: 'linear',
        arrows: true,
        infinite: true,
        autoplay: false
      },
      settings: {
        slidesToShow: 3,
        dots: true,
        autoplay: false,
        arrows: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      },
      email: '',
      full_name: '',
      additional_info: '',
      Moon,
      HeroMobile,
      MainBtn,
      Sun,
      Telephones,
      Footer,
      features: [
        {
          img: F1,
          title: 'Weather Radar'
        },
        {
          img: F2,
          title: '16-day forecast'
        },
        {
          img: F3,
          title: 'Air Quality Index'
        },
        {
          img: F4,
          title: 'Ad-free'
        }
      ],
      apps: [
        {
          mainPic: App1,
          decPic: AppDec1,
          title: 'Weather',
          description: 'Our Weather app allows you to view and manage weather information for multiple cities at the same time. You can predict the weather for the next few hours and days.'
        },
        {
          mainPic: App2,
          decPic: AppDec2,
          title: 'Radar',
          description: 'The Weather Radar feature is a powerful tool for those who value accurate and up-to-date weather information. It allows you to be aware of all atmospheric changes in real time, ensuring your safety and comfort.'
        },
        {
          mainPic: App3,
          decPic: AppDec3,
          title: 'Air Quality Index',
          description: 'Air Quality lets you take charge of your health and the environment by taking steps to stay comfortable and safe. With it, you can monitor air pollution levels, assess health impacts, get safety recommendations, track air quality trends, and avoid risky areas.'
        },
        {
          mainPic: App4,
          decPic: AppDec4,
          title: '16 days forecast',
          description: '"16 Day Forecast" provides you with long-term weather forecasts for the next two weeks. With its help, you can plan your activities in advance, having an understanding of temperature changes, precipitation conditions and possible changes in atmospheric pressure.'
        },
        {
          mainPic: App5,
          decPic: AppDec5,
          title: 'Weather conditions',
          description: 'Weather conditions are updated every minute, and you can check the latest and accurate weather forecast at any time.You will get your real-time local weather and detailed weather information in 16 days automatically.'
        },
        {
          mainPic: App6,
          decPic: AppDec6,
          title: 'Ultraviolet Index',
          description: 'The UV Index feature provides important information about the level of ultraviolet radiation in your area. This index assesses the potential risks to your skin and eyes from exposure to solar radiation.'
        },
        {
          mainPic: App7,
          decPic: AppDec7,
          title: 'Sunset',
          description: 'The sunset feature in our weather app offers the ability to never miss a sunset. Now you can get accurate information about the time the sun will set and also enjoy beautiful visual representations of the sunset.'
        },
        {
          mainPic: App8,
          decPic: AppDec8,
          title: 'System widgets',
          description: 'System widgets are a feature that allows you to instantly get accurate weather data right on your desktop or device screen. You can easily monitor real-time weather forecasts without opening the app. Stay up to date with weather changes effortlessly using system widgets.'
        }
      ],
      currents: [
        {
          img: Current1,
          title: 'Snowfall Night'
        },
        {
          img: Current2,
          title: 'Rainy Night'
        },
        {
          img: Current3,
          title: 'Thunderstorm Day'
        },
        {
          img: Current4,
          title: 'Cloudy day'
        },
        {
          img: Current5,
          title: 'Sunny day'
        },
        {
          img: Current6,
          title: 'Clear night'
        }
      ]
    }
  },
  methods: {
    submit () {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          full_name: this.full_name,
          email: this.email,
          additional_info: this.additional_info
        })
      }
      fetch(`${ process.env.VUE_APP_API }/api/v1/contact_me_form/`,
        requestOptions)
        .then(response => {
          if (response?.status === 200) {
            alert('Відправлено')
            this.email = ''
            this.full_name = ''
            this.additional_info = ''
          }
        })
    }
  }
}
</script>
